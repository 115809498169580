.canvasAnimateMouns {
	width: 100%;
	height: 140%;
	position: fixed;
	top: 0;
	left: 0;
	background-image: linear-gradient(to top, #2e164e, #626f96);
}

#animateHtml {
	position: fixed;
	top: 0; left: 0;
	width: 100%;
	height: 100%;
}

#animateHtml > * {
	position: absolute;
}

#animateHtml .guy_anm_parent {
	background: transparent;
}

#animateHtml .guy_anm_bl {
	position: absolute;
	bottom: 0;
	height: 70%;
	animation: guyAnimate 6s;
	animation-delay: 1.5s;
	opacity: 0;
}

@keyframes guyAnimate {
  0% { 
  	opacity: 1;
  	left: 0%;
  	bottom: 0%;
  }
  50% {
  	opacity: 1;
  }
  65% {
  	opacity: 0;
  }
  100% { 
  	opacity: 0;
  	left: 90%;
  	bottom: 80%;
  }
}