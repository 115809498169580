@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&display=swap);
.showPopUpPage {
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.showPopUpPage_hidden {
  display: none
}

.wrapper-video-popup {
  position: relative;
}

.showPopUp iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.showPopUp {
  width: 720px;
  height: 500px;
  margin: 50px auto;
  background-color: white;
}

.showAnotherPopUp {
  width: 768px;
  height: 432px;
  margin: 50px auto;
  background-color: white;
}

.showAnotherPopUp iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 21px;
  height: 32px;
  cursor: pointer;
}

.close:hover {
  opacity: 1;
}

.close:before, .close:after {
  position: absolute;
  left: 9px;
  top: 7px;
  content: ' ';
  height: 25px;
  width: 3px;
  background-color: #fff;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

/* .pop-up-manager {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
}
.pop-up-manager_hidden {
  display: none;
}
.pop-up-manager__undercover {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.6);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  cursor: pointer;
}
.pop-up-manager__content {
  margin-top: 30px;
  margin-bottom: 50px;
  align-self: flex-end;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}
.pop-up-manager__content-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  position: relative;
}
.pop-up-manager__close {
  margin-bottom: 4px;
  display: flex;
  width: max-content;
  align-self: flex-end;
  align-items: center;
  cursor: pointer;
  color: #fff;
}
.pop-up-manager__close-title {
  margin: 0 13px 4px 0;
  font-size: 16px;
  line-height: 1;
}
.pop-up-manager__check {
  align-self: center;
  height: 11.3px;
  width: 11.3px;
} */

@media screen and (max-width: 768px) {
  .wrapper-video-popup {
    position: relative;
    width: 90%;
    height: 51.3vw;
    bottom: 40px;
  }

  .showPopUp {
    width: 100%;
    height: 100%;
  }

  .showAnotherPopUp {
    width: 100%;
    height: 100%;
  }
}

.mail-form {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.mail-form .timerRepText {
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  margin: 20px 0;
}

.mail-form__contentagree {
  color: #666666;
  margin-bottom: 20px;
  overflow: hidden;
  border-bottom: 2px solid rgba(0,0,0,.1);
  padding-bottom: 20px;
}

.mail-form__contentagreescroll {
  overflow: hidden;
  overflow-y: auto;
  height: 235px;
  width: calc(100% + 60px);
  padding-right: 80px;
  padding-left: 20px;
}

.mail-form__contentagreescroll p {
  margin: 0px;
  margin-top: 20px;
}

.mail-form__contentagreescroll .boldtext {
  font-weight: 700;
  margin-top: 0;
}

.mail-form__contentagreescroll a {
  text-decoration: underline;
  color: #2ba4fc;
}

.mail-form__name-text {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: -10px;
}

.mail-form__top-name-text {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 0px;
}

.mail-form__name-text-span {
  font-size: 14px;
  text-align: center;
  width: 80%;
  
}

.mail-form__content {
  line-height: normal;
  padding: 20.3px 0 18.3px 0;
  background-color: #fff;
  width: 459.2px;
  border-radius: 10px;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1);
}

.mail-form__content-token-sale {
  width: 460px!important;
}

.mail-form__title {
  font-family: Geometria;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

.mail-form__arrows {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABCAgMAAACazzhGAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURUdwTHdg03lh1Hli1Hmoq0YAAAADdFJOUwBCv2PnR0QAAACESURBVDjL7dSxEYAgDAVQsKFgCEdwBTfDsexcgREcgsJGvgQMBdHWs4Ai3P2DB02i1KpojbO61xDz5jwHFkuqGoGDCXuqBicHGw4iAEaAWAJfTxCSglANQgwqYkGIRkWGgriO/AMJGYmtGt+utKh4VnysE98ST23aNLJodTEMxLhoB8oFrGl7eFQZ9ogAAAAASUVORK5CYII=) no-repeat 0 0;
  width: 22px;
  height: 22px;
  background-size: cover;
}

.mail-form__bonus {
  margin-top: 10.6px;
  font-family: Geometria;
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: #7762d5;
}

.mail-form__description {
  margin-top: 10.6px;
  font-size: 14px;
  text-align: center;
}

.mail-form__button, .mail-form__input {
  margin: 0 30.6px;
}

.mail-form__timer {
  margin-top: 26.2px;
}

.mail-form__cost {
  margin-bottom: 23.6px;
  padding: 8.2px 31.1px 8.6px 31.1px;
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f6;
  margin-top: 6px;
  align-items: center;
}

.mail-form__cost-title {
  font-size: 12px;
  color: #999;
}
.mail-form__cost-value {
  font-weight: 600;
  font-size: 18px;
  color: #e783bb;
}

.mail-form__later {
  text-align: right;
}
.mail-form__button {
  margin-top: 10.1px;
}
.mail-form__video {
  background-image: url(/static/media/new_mainBlock_screen.394a5d2f.png);
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 500px;
  height: 250px;
}
.mail-form__play {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: none;
}

.button_gradient.focus_bun_grdn:hover {
  background: white;
  color: #6e5fd6;
  box-shadow: 1px 1px 10px rgba(0,0,0,.2);
}

.mail-form__textStatus {
  font-size: 14px;
  text-align: center;
  margin: 20px 0 0;
}

.mail-form__textStatus.success {
  color: #8bc34a;
}

.mail-form__textStatus.error {
  color: #ff4040;
}

.mail-form__name-text-span-token-sale {
  width: 255px;
}

.mail-form__textStatus.loading {
  color: #666666;
}

.main-block__formStarted {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  flex-wrap: wrap;
}

.main-block__formStarted input {
  color: white;
  font-size: 14px;
  border: none;
  border-bottom: 2px solid white;
  padding: 10px 20px;
  background: none;
  font-weight: bold;
  text-align: center;
}

.main-block__formStarted .button_send {
  text-transform: uppercase;
  border: 2px solid white;
  padding: 10px 50px;
  border-radius: 100px;
  font-weight: bold;
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
}

.main-block__formStarted .button_send:hover {
  background: linear-gradient(90deg,#f287b7,#6c5ed6);
  border-color: rgba(0,0,0,0);
}

.main-block__prizes {
  margin-top: 30px;
}

.main-block__prizes img {
  max-height: 120px;
  margin-right: 20px;
}

.main-block__cont {
  max-width: 450px;
  margin: 0 auto;
}

.mail-form__legals {
  display: flex;
  max-width: 500px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px; 
}

.mail-form__item_legals {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.main-block .img_legal {
  height: 30px;
}

.main-block .text_legal {
  color: white;
  font-size: 12px;
  margin-left: 10px;
}

@media screen and (max-width: 400px) {
  .main-block__cont {
    width: 100%;
    padding: 0 20px;
  }
  .main-block__formStarted .button_send {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .mail-form__content {
    width: 300px;
  }
  .mail-form__video {
    width: 500px;
    height: 250px;
    border-radius: 0px;
  }
  .mail-form__cost {
    padding: 8.2px 20.3px 8.6px 20.3px;
  }
  .mail-form__input, .mail-form__button {
    margin: 0 20px;
  }
  .mail-form__button {
    margin-top : 10.1px;
  }
}

@media screen and (max-width: 1439px) {
  .mail-form__content-token-sale {
    width: 300px!important;
  }
}

@media screen and (max-width: 1023px) {
  .mail-form__content-token-sale {
    display: none;
  }
}

@media screen and (min-width: 1440px) {

}

.main-block {
  background: linear-gradient(180deg, rgba(76, 32, 115, 1) 0%, rgba(8, 159, 195, 1) 100%);
  background: none;
}

.main-block .icons_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.main-block .icons_wrapper .icon_block {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  max-width: 500px;
}

.main-block .icons_wrapper .icon_block a {
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes pulseOpacity {
  from {opacity: .1;}
  to {opacity: 1;}
}

@keyframes pulseOpacity {
  from {opacity: .1;}
  to {opacity: 1;}
}

.main-block__content {
  padding: 180px 40px 0!important;
}

.main-block .icons_wrapper .icon_block img {
  width: 30px;
  height: 30px;
  margin: 5px;
}

.main-block .icons_wrapper .icon_block span {
  display: flex;
  color: #754ADD;
  background: white;
  padding: 5px;
  height: 30px;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
}

.main-block__header {
  max-width: 450px;
}

.main-block__ico-info-l {
  display: none;
}

.main-block__ico-info-m {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;

  margin: 30.1px auto 0 auto;
}

.main-block__form {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 46px auto 0 auto;
}

.main-block__header {
  padding-top: 0px;
  margin: 0 auto;
  width: 620px;
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.main-block__header2 {
  font-weight: 300;
  font-size: 32px;
  color: #ffffff;
  margin-top: 10px;
}
.main-block__header3 {
  font-weight: 300;
  font-size: 15px;
  color: #ffffff;
  margin-top: 10px;
  font-weight: 500;
}

.main-block__docs {
  display: flex;
  margin-top: 60px;
  display: none;
}

.main-block__docs__item {
  display: flex;
  justify-content: center;
  margin: 0 10px;
  align-items: center;
}

.main-block__docs__item .text_docs_item {
  color: white;
  font-weight: bold;
  margin-left: 10px;
  font-size: 12px;
}

.main-block__docs__item img {
  height: 20px;
}

.main-block__textStatus {
  font-size: 14px;
  margin: 10px 0 0;
  color: #9c9b9b;
} 

.main-block__textStatus.error {
  color: #ff4040;
}
.main-block__textStatus.loading {
  color: #666666;
}
.main-block__textStatus.success {
  color: #8bc34a;
}

.main-block .qr_code {
  object-fit: contain;
}

.main-block .qr_code img {
  display: flex;
  width: 200px;
  margin: 10px 0;
  align-self: center;
  flex: 0 0 auto;
}

.main-block .qr_code.desktop {
  display: flex;
}

.main-block .qr_code.mobile {
  display: none;
  margin-left: 5%;
} 

.main-block__content {
  max-width: 1224px!important;
}

.button_send_main {
  background: linear-gradient(90deg, rgba(242, 135, 183, 1) 0%, rgba(108, 94, 214, 1) 100%);
  border-radius: 24px;
  display: block;
  box-shadow: 6px 7px 22px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  transition: box-shadow 0.3s;
  float: right;
  padding: 10px 60px; 
}

@media screen and (min-width: 1024px) {
  .main-block {
  }
  .main-block__header {
    width: 454px;
  }
  .main-block__content {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 180px 40px 0!important;
  }
  .main-block__ico-info-m {
    display: none;
  }
  .main-block__ico-info-l {
    margin-top: 30.2px;
    display: block;
  }
  .main-block__form {
    margin-top: 0;
  }
}

@media screen and (min-width: 1440px) {
  .main-block {
  }
  .main-block__content {
    width: 1135px;
  }
  .main-block__header {
    width: 100%;
  }
}

@media screen and (max-width: 1439px) {
  .main-block__content {
  } 
}

@media screen and (max-width: 1023px) {
  .main-block__header {
    padding-top: 0px;
  } 
}

@media screen and (max-width: 767px) {
  .main-block__content {
    max-width: 500px;
    margin: 0 auto;
    padding: 200px 40px 0!important;
  }
  .main-block__header {
    margin: 0 auto;
    width: 100%;
    word-wrap: break-word;
  }
  .main-block__cont {
    padding-top: 10px;
    margin: 0 auto;
    width: 100%;
    word-wrap: break-word;
  }
  .main-block__formStarted .button_send {
    margin-top: 20px;
  }
  .main-block__form {
    padding-top: 10px;
    margin: 0 auto;
    width: 90%;
  }
  .mail-form {
    width: 100%;
  }
  .mail-form__video {
    width: 80%;
    margin: 0 auto;
    height: 180px;
  }
  .mail-form__content {
    width: 90%;
    margin: 0 auto;
  }
  .main-block__ico-info-m {
    width: 80%;
  }
  .ico-info {
    width: 100%;
  }
  .ico-info__progressBar {
    width: 100%;
  }
  .main-block__form {
    margin-top: 20px;
  }

  .main-block .qr_code.desktop {
    display: none;
  }

  .main-block .qr_code.mobile {
    display: flex;
  } 
}

@media screen and (max-width: 500px) {
  .main-block__content {
    padding: 250px 40px 0!important;
  }
}



.canvasAnimateMouns {
	width: 100%;
	height: 140%;
	position: fixed;
	top: 0;
	left: 0;
	background-image: linear-gradient(to top, #2e164e, #626f96);
}

#animateHtml {
	position: fixed;
	top: 0; left: 0;
	width: 100%;
	height: 100%;
}

#animateHtml > * {
	position: absolute;
}

#animateHtml .guy_anm_parent {
	background: transparent;
}

#animateHtml .guy_anm_bl {
	position: absolute;
	bottom: 0;
	height: 70%;
	-webkit-animation: guyAnimate 6s;
	        animation: guyAnimate 6s;
	-webkit-animation-delay: 1.5s;
	        animation-delay: 1.5s;
	opacity: 0;
}

@-webkit-keyframes guyAnimate {
  0% { 
  	opacity: 1;
  	left: 0%;
  	bottom: 0%;
  }
  50% {
  	opacity: 1;
  }
  65% {
  	opacity: 0;
  }
  100% { 
  	opacity: 0;
  	left: 90%;
  	bottom: 80%;
  }
}

@keyframes guyAnimate {
  0% { 
  	opacity: 1;
  	left: 0%;
  	bottom: 0%;
  }
  50% {
  	opacity: 1;
  }
  65% {
  	opacity: 0;
  }
  100% { 
  	opacity: 0;
  	left: 90%;
  	bottom: 80%;
  }
}
.startTimer {
  background: #bc77e4;
  width: 100%;
  color: white;
  height: 80px;
  overflow-y: auto;
}

.startTimer a {
  color: white;
  text-decoration: underline;
}

.startTimer .content {
  display: flex;
  justify-content: center;
  padding: 10px 0 5px;
}

.startTimer .content .titleText {
  font-size: 22px;
  text-align: center;
  flex-wrap: wrap;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 60%;
}

.startTimer .content .titleText span {
  font-size: 13px;
}


.headeFixed {
  width: 100%;
  top: 0;
  z-index: 9;
  position: fixed;
}
.menu {
  width: 100%;
  color: white;
  padding: 10px 40px;
  background-color: #3a2f64;
  z-index: 9;
  position: fixed;
}
.menu .container-menu {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu .logo {
  width: 105px;
}
.menu a {
  color: white;
}
.menu .drop-down {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  margin-left: 15px;
  position: relative;
}

.menu .drop-down:hover {
}
.menu .drop-down .item {
  margin-left: 0px;
}
.menu .drop-down .arrow_public {
  width: 13px;
  height: auto;
  margin-left: 8px;
  cursor: pointer;
}
.menu .menu-item {
  display: flex;
  align-items: center;
  position: relative;
}
.menu .menu-item-burger {
  cursor: pointer;
}
.menu .item {
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
}

.menu-item > .item {
  margin: 0 10px;
}

.menu .btn {
  padding: 0 25px;
  height: 40px;
  border-radius: 25px;
  border: solid 1px #ffffff;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.menu .btn:hover {
  color: #6e5fd6;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
.menu .menu-768px {
  display: none;
  justify-content: flex-end;
  align-items: center;
}
.menu .menu-item-burger {
  margin-left: 35px;
  position: relative;
}
.menu .menu-icon {
  padding-top: 5px;
  width: 29px;
  height: 29px;
}
.showMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 280px;
  padding-bottom: 25px;
  background-color: #4c4272;
  font-size: 32px;
  position: absolute;
  top: 60px;
  right: 0;
}
.menu .showMenu .itemMini {
  font-size: 16px;
  font-weight: normal;
  padding: 14px 0 10px 0;
  padding-left: 25px;
}
.menu .joinpresale {
  display: none;
}
.menu .showMenu .itemMini:hover {
  border-left: 4px solid #7f00e3;
}
.showMenu-hide {
  display: none;
}
.menu .publicDocs {
  display: flex;
  position: absolute;
  padding: 0 17px;
  padding-top: 17px;
  top: 10px;
  right: 0;
  color: #7962d4;
  border-radius: 5%;
  background-color: white;
  flex-direction: column;
  box-shadow: 0 0 40px black;
  min-width: 150px;
}
.menu .trPublicDocs {
  right: 380px;
}
.menu .drop-down .publickOnMouseLeave {
  position: absolute;
  background-color: transparent;
  width: 115px;
  height: 20px;
  top: 80px;
  margin-left: 10px;
}
.menu .menu-flags {
  background-size: contain;
  width: 32px;
  height: 20px;
  margin-right: 10px;
  background-position: center center;
  background-repeat: no-repeat;
}
.menu .menu-lang {
  position: relative;
  top: 1px;
}
.menu .menu-lang-flags {
  display: flex;
}
.menu .language {
  position: absolute;
  padding: 0 17px;
  padding-top: 17px;
  color: #7962d4;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  top: 10px;
  right: 0;
  z-index: 2;
}
.menu .publicDocs .show-publicDocs,
.menu .language .show-language  {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  cursor: pointer;
}
.menu .language .itemMini2 {
  font-size: 16px;
  font-weight: 300;
  color: #7962d4;
  position: relative;
  top: 2px;
}
.menu .publicDocs .itemMini2 {
  font-size: 16px;
  font-weight: 300;
  color: #7962d4;
}
.menu .publicDocs .doc_icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwBAMAAACLacUmAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAPUExURUdwTHhi1Xlh1Hdg03li1GkGdogAAAAEdFJOUwB/v0D5v7oQAAAAdUlEQVQoz+3TwQmAMAwF0GgWUMkAOoLgApW//0zSpISkPXkV//HxaQkkRAJPIc2MkF1JIt1KwFADjlWzeQ1Y7NEJuKwWia0WiayWyGqJrJaJ6wiZai2R5S3xSHS2SQP5vz99mNqS+6p3p6BrIr2UfFbtZqQvPXWyW4CKQLwuAAAAAElFTkSuQmCC);
  background-size: cover;
  width: 12px;
  height: 16px;
  cursor: pointer;
  margin-right: 15px;
}
.menu .showMenu .publicDocs-hide {
  display: none;
}

@media (max-width: 1150px) {
  .menu .publicDocs {
    box-shadow: none;
  }
  .menu .fixedSocIcon {
    right: 30px;
  }
}

@media (max-width: 1150px) {
  .showMenu {
    max-height: 450px;
    overflow-y: scroll;
  }
  .menu .publicDocs {
    position: relative;
    top: 0px;
    right: 0px;
    width: auto;
    height: auto;
    background-color: #fff0;
    color: white;
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    padding-top: 0px;
    padding-bottom: 170px;
  }
  .menu .publicDocs .doc_icon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwBAMAAACLacUmAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAPUExURUdwTP///////////////xPgMRoAAAAEdFJOUwB/v0D5v7oQAAAAdUlEQVQoz+3TwQmAMAwF0GgWUMkAOoLgApW//0zSpISkPXkV//HxaQkkRAJPIc2MkF1JIt1KwFADjlWzeQ1Y7NEJuKwWia0WiayWyGqJrJaJ6wiZai2R5S3xSHS2SQP5vz99mNqS+6p3p6BrIr2UfFbtZqQvPXWyW4CKQLwuAAAAAElFTkSuQmCC);
    margin-right: 5px;
    margin-left: 0px;
  }
  .menu .publicDocs .show-publicDocs:first-of-type {
    margin-bottom: 20px;
  }
  .menu .publicDocs .show-publicDocs {
    margin: 20px 0;
  }
  .menu .publicDocs .itemMini2 {
    color: white;
  }
  .menu .drop-down .arrow_public {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .menu .drop-down {
    height: 0;
    padding-top: 0;
    margin-left: 0px;
    padding-left: 25px;
    padding-bottom: 0px;
    margin: 20px 0;
  }
}

* {
  font-family: 'Quicksand';
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
}

.contentText {
  position: relative;
  margin-bottom: -15%;
}

.size {
  width:  100%;
  margin: 0 auto;
  max-width: 1440px;
}

.menu_block.fixed {
  background: rgba(50,26,79,.2);
  padding: 20px 0;
  z-index: 999;
}

.menu_block.fixed .header{
  padding-top: 0px;
}

.menu_block.fixed .header ul.menu{
  display: block;
  float: right;
  list-style: none;
  height: 45px;
  line-height: 45px;
}

.menu_block.fixed .header .left_lead .slog{
    display: none;
}

.menu_block.fixed .header ul.menu li a:before{
  content: '';
  bottom: 0px;
}
/**/

.menu_block .header
{
    padding-top: 85px;
    display: block;
    width: 1024px;
    margin:  0 auto;
}

.menu_block .header .left_lead
{
    display: block;
    float: left;
}

.menu_block .header .left_lead a.logo
{
    display: block;
    float:  left;
    margin-right: 45px;
}

.menu_block .header .left_lead .slog
{
    display: block;
    float:  right;
    max-width: 170px;
    font-size: 16px;
    padding-left: 45px;
    position: relative;
}

.menu_block .header .left_lead .slog:before
{
    display: block;
    content: '';
    width: 1px;
    height: 155px;
    position: absolute;
    background: #fff;
    left: 0;
    bottom: -15px;
    opacity: 0.5;
}

.menu_block .header .left_lead .slog span
{
    font-size: 14px;
    display: block;
    opacity: 0.5;
}

.menu_block .header ul.menu
{
    display: block;
    float: right;
    list-style: none;
}

.menu_block .header ul.menu li
{
    display: block;
    float: left;
}

.menu_block .header ul.menu li a
{
    display: block;
    float: left;
    color: #fff;
    position: relative;
    text-decoration: none;
    font-size: 15px;
    margin-left: 20px;
}

.menu_block .header ul.menu li a:before
{
    content: '';
    display: block;
    position: absolute;
    bottom: -27px;
    width: 0;
    height: 6px;
    background: #6C5ED6;
    transition: width 0.5s;
}


.menu_block .header ul.menu li a:hover:before {
  width: 100%;
}

.header_blocks {
  font-size: 32px !important;
  font-weight: bold !important;
  color: #ffffff !important;
  margin: 0px !important;
  padding: 0px !important;
  padding-left: 40px !important;
  padding-top: 80px !important;
  display: inline-block !important;
}

.block_1 .header_blocks {
  width: 65%;
}

.asyncLoading {
  text-align: center;
  margin-top: 20px;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

