.showPopUpPage {
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.showPopUpPage_hidden {
  display: none
}

.wrapper-video-popup {
  position: relative;
}

.showPopUp iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.showPopUp {
  width: 720px;
  height: 500px;
  margin: 50px auto;
  background-color: white;
}

.showAnotherPopUp {
  width: 768px;
  height: 432px;
  margin: 50px auto;
  background-color: white;
}

.showAnotherPopUp iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 21px;
  height: 32px;
  cursor: pointer;
}

.close:hover {
  opacity: 1;
}

.close:before, .close:after {
  position: absolute;
  left: 9px;
  top: 7px;
  content: ' ';
  height: 25px;
  width: 3px;
  background-color: #fff;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

/* .pop-up-manager {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
}
.pop-up-manager_hidden {
  display: none;
}
.pop-up-manager__undercover {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.6);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  cursor: pointer;
}
.pop-up-manager__content {
  margin-top: 30px;
  margin-bottom: 50px;
  align-self: flex-end;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}
.pop-up-manager__content-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  position: relative;
}
.pop-up-manager__close {
  margin-bottom: 4px;
  display: flex;
  width: max-content;
  align-self: flex-end;
  align-items: center;
  cursor: pointer;
  color: #fff;
}
.pop-up-manager__close-title {
  margin: 0 13px 4px 0;
  font-size: 16px;
  line-height: 1;
}
.pop-up-manager__check {
  align-self: center;
  height: 11.3px;
  width: 11.3px;
} */

@media screen and (max-width: 768px) {
  .wrapper-video-popup {
    position: relative;
    width: 90%;
    height: 51.3vw;
    bottom: 40px;
  }

  .showPopUp {
    width: 100%;
    height: 100%;
  }

  .showAnotherPopUp {
    width: 100%;
    height: 100%;
  }
}
