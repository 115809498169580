.startTimer {
  background: #bc77e4;
  width: 100%;
  color: white;
  height: 80px;
  overflow-y: auto;
}

.startTimer a {
  color: white;
  text-decoration: underline;
}

.startTimer .content {
  display: flex;
  justify-content: center;
  padding: 10px 0 5px;
}

.startTimer .content .titleText {
  font-size: 22px;
  text-align: center;
  flex-wrap: wrap;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 60%;
}

.startTimer .content .titleText span {
  font-size: 13px;
}

