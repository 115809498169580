@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&display=swap');

* {
  font-family: 'Quicksand';
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
}

.contentText {
  position: relative;
  margin-bottom: -15%;
}

.size {
  width:  100%;
  margin: 0 auto;
  max-width: 1440px;
}

.menu_block.fixed {
  background: rgba(50,26,79,.2);
  padding: 20px 0;
  z-index: 999;
}

.menu_block.fixed .header{
  padding-top: 0px;
}

.menu_block.fixed .header ul.menu{
  display: block;
  float: right;
  list-style: none;
  height: 45px;
  line-height: 45px;
}

.menu_block.fixed .header .left_lead .slog{
    display: none;
}

.menu_block.fixed .header ul.menu li a:before{
  content: '';
  bottom: 0px;
}
/**/

.menu_block .header
{
    padding-top: 85px;
    display: block;
    width: 1024px;
    margin:  0 auto;
}

.menu_block .header .left_lead
{
    display: block;
    float: left;
}

.menu_block .header .left_lead a.logo
{
    display: block;
    float:  left;
    margin-right: 45px;
}

.menu_block .header .left_lead .slog
{
    display: block;
    float:  right;
    max-width: 170px;
    font-size: 16px;
    padding-left: 45px;
    position: relative;
}

.menu_block .header .left_lead .slog:before
{
    display: block;
    content: '';
    width: 1px;
    height: 155px;
    position: absolute;
    background: #fff;
    left: 0;
    bottom: -15px;
    opacity: 0.5;
}

.menu_block .header .left_lead .slog span
{
    font-size: 14px;
    display: block;
    opacity: 0.5;
}

.menu_block .header ul.menu
{
    display: block;
    float: right;
    list-style: none;
}

.menu_block .header ul.menu li
{
    display: block;
    float: left;
}

.menu_block .header ul.menu li a
{
    display: block;
    float: left;
    color: #fff;
    position: relative;
    text-decoration: none;
    font-size: 15px;
    margin-left: 20px;
}

.menu_block .header ul.menu li a:before
{
    content: '';
    display: block;
    position: absolute;
    bottom: -27px;
    width: 0;
    height: 6px;
    background: #6C5ED6;
    transition: width 0.5s;
}


.menu_block .header ul.menu li a:hover:before {
  width: 100%;
}

.header_blocks {
  font-size: 32px !important;
  font-weight: bold !important;
  color: #ffffff !important;
  margin: 0px !important;
  padding: 0px !important;
  padding-left: 40px !important;
  padding-top: 80px !important;
  display: inline-block !important;
}

.block_1 .header_blocks {
  width: 65%;
}

.asyncLoading {
  text-align: center;
  margin-top: 20px;
  color: white;
}
