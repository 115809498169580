.headeFixed {
  width: 100%;
  top: 0;
  z-index: 9;
  position: fixed;
}
.menu {
  width: 100%;
  color: white;
  padding: 10px 40px;
  background-color: #3a2f64;
  z-index: 9;
  position: fixed;
}
.menu .container-menu {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu .logo {
  width: 105px;
}
.menu a {
  color: white;
}
.menu .drop-down {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  margin-left: 15px;
  position: relative;
}

.menu .drop-down:hover {
}
.menu .drop-down .item {
  margin-left: 0px;
}
.menu .drop-down .arrow_public {
  width: 13px;
  height: auto;
  margin-left: 8px;
  cursor: pointer;
}
.menu .menu-item {
  display: flex;
  align-items: center;
  position: relative;
}
.menu .menu-item-burger {
  cursor: pointer;
}
.menu .item {
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
}

.menu-item > .item {
  margin: 0 10px;
}

.menu .btn {
  padding: 0 25px;
  height: 40px;
  border-radius: 25px;
  border: solid 1px #ffffff;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.menu .btn:hover {
  color: #6e5fd6;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
.menu .menu-768px {
  display: none;
  justify-content: flex-end;
  align-items: center;
}
.menu .menu-item-burger {
  margin-left: 35px;
  position: relative;
}
.menu .menu-icon {
  padding-top: 5px;
  width: 29px;
  height: 29px;
}
.showMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 280px;
  padding-bottom: 25px;
  background-color: #4c4272;
  font-size: 32px;
  position: absolute;
  top: 60px;
  right: 0;
}
.menu .showMenu .itemMini {
  font-size: 16px;
  font-weight: normal;
  padding: 14px 0 10px 0;
  padding-left: 25px;
}
.menu .joinpresale {
  display: none;
}
.menu .showMenu .itemMini:hover {
  border-left: 4px solid #7f00e3;
}
.showMenu-hide {
  display: none;
}
.menu .publicDocs {
  display: flex;
  position: absolute;
  padding: 0 17px;
  padding-top: 17px;
  top: 10px;
  right: 0;
  color: #7962d4;
  border-radius: 5%;
  background-color: white;
  flex-direction: column;
  box-shadow: 0 0 40px black;
  min-width: 150px;
}
.menu .trPublicDocs {
  right: 380px;
}
.menu .drop-down .publickOnMouseLeave {
  position: absolute;
  background-color: transparent;
  width: 115px;
  height: 20px;
  top: 80px;
  margin-left: 10px;
}
.menu .menu-flags {
  background-size: contain;
  width: 32px;
  height: 20px;
  margin-right: 10px;
  background-position: center center;
  background-repeat: no-repeat;
}
.menu .menu-lang {
  position: relative;
  top: 1px;
}
.menu .menu-lang-flags {
  display: flex;
}
.menu .language {
  position: absolute;
  padding: 0 17px;
  padding-top: 17px;
  color: #7962d4;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  top: 10px;
  right: 0;
  z-index: 2;
}
.menu .publicDocs .show-publicDocs,
.menu .language .show-language  {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  cursor: pointer;
}
.menu .language .itemMini2 {
  font-size: 16px;
  font-weight: 300;
  color: #7962d4;
  position: relative;
  top: 2px;
}
.menu .publicDocs .itemMini2 {
  font-size: 16px;
  font-weight: 300;
  color: #7962d4;
}
.menu .publicDocs .doc_icon {
  background-image: url(../../images/doc_icon_violet.png);
  background-size: cover;
  width: 12px;
  height: 16px;
  cursor: pointer;
  margin-right: 15px;
}
.menu .showMenu .publicDocs-hide {
  display: none;
}

@media (max-width: 1150px) {
  .menu .publicDocs {
    box-shadow: none;
  }
  .menu .fixedSocIcon {
    right: 30px;
  }
}

@media (max-width: 1150px) {
  .showMenu {
    max-height: 450px;
    overflow-y: scroll;
  }
  .menu .publicDocs {
    position: relative;
    top: 0px;
    right: 0px;
    width: auto;
    height: auto;
    background-color: #fff0;
    color: white;
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    padding-top: 0px;
    padding-bottom: 170px;
  }
  .menu .publicDocs .doc_icon {
    background-image: url(../../images/doc_icon.png);
    margin-right: 5px;
    margin-left: 0px;
  }
  .menu .publicDocs .show-publicDocs:first-of-type {
    margin-bottom: 20px;
  }
  .menu .publicDocs .show-publicDocs {
    margin: 20px 0;
  }
  .menu .publicDocs .itemMini2 {
    color: white;
  }
  .menu .drop-down .arrow_public {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .menu .drop-down {
    height: 0;
    padding-top: 0;
    margin-left: 0px;
    padding-left: 25px;
    padding-bottom: 0px;
    margin: 20px 0;
  }
}
